export default async function editService(se){
    return {
        name: "Modifiko sherbimin",
        submit: {
            label: "Modifiko sherbimin",
            action: 'submitService'
        },
        fields: [

            [{
                value: se.code,
                name: 'code',
                label: 'Kodi',
                type: 'text-field',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme',
                unique: {
                    model: 'service',
                    field: 'code',
                    where: [
                        {
                        column: 'guid',
                        condition: '!=',
                        value: se.guid
                    }
                    ]
                },
                left_icon: 'fa-solid fa-hashtag'
            },
            {
                value: se.name,
                name: 'name',
                label: 'Emri',
                type: 'text-field',
                style: 'flex-grow: 0.4',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            }],
            [
                {
                    value: se.description,
                    name: "description",
                    label: "Pershkrimi",
                    type: "text-field",
                    style: "flex-grow: 0.6",
                },
            ],
            {
                value: se.type,
                name: "type",
                label: "Tipi",
                type: 'select-field',
                options: [
                    {id:1, label: 'Transport'},
                    {id:2, label: 'Montim'}
                ],
                style: "flex-grow: 0.6",
                required: true
            },
            {
                value: se.price,
                name: 'price',
                type: 'number-field',
                label: 'Cmimi',
                style: "flex-grow: 0.6",
            }
        ]
    }
}
