export default async function createService(){
      return {
        name: "Krijo Sherbim",
        submit: {
          label: "Krijo",
          action: "submitService",
        },
        fields: [
          [
            {
              value: "",
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "service",
                field: "code",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: "",
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.6",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          {
            value: "",
            name: "description",
            label: "Pershkrimi",
            type: "text-field",
            style: "flex-grow: 0.6",
          },
          {
            name: "type",
            label: "Tipi",
            type: 'select-field',
            options: [
              {id:1, label: 'Transport'},
              {id:2, label: 'Montim'}
            ],
            style: "flex-grow: 0.6",
            required: true
          },
          {
            value: "",
            name: 'price',
            type: 'number-field',
            label: 'Cmimi',
            style: "flex-grow: 0.6",
          }
        ],
      };
    }
  
  